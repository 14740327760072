import { STATUS_INITIAL, STATUS_APPROVED, STATUS_REVIEW, STATUS_CONFLICT, STATUS_DRAFT, STATUS_REJECTED } from '../constants/employeeStatus';
import { TYPE_TEXT, TYPE_DATE, TYPE_NUMBER, TYPE_BOOLEAN, TYPE_DATE_TIME_DAY, TYPE_DECIMAL } from '../constants/types';
import { FIELD_EMPLOYEE, FIELD_USER, FIELD_ENUM } from '../constants/fields';
import { getFullName as getFullUserName } from './userUtilities';

/**
 * Gets the full name of an employee.
 * This should be used everywhere, except from sortable list views
 * (See `getSortableFullName` instead)
 *
 * @param {object} employee
 */
export const getFullName = (employee) => {
  if (!employee) return null;

  return [
    employee.title,
    employee.initials,
    (employee.lastName || employee.birthName),
    employee.postNominalTitle
  ].filter((v) => v).join(' ');
};

/**
 * Gets the full name in a sortable way. This should be used for sortable lists only.
 *
 * @param {object} employee
 */
export const getSortableFullName = (employee) => {
  if (!employee) return null;

  return [
    `${employee.lastName},`,
    employee.initials,
  ].filter((v) => v).join(' ');
};

/**
 * Gets the list of the coutries attached to the employee.
 *
 * @param {object} employee
 */
export const getCountryDetailsLabels = (employee) => {
  if (!employee || !employee.regulationSpecifics.country || employee.regulationSpecifics.country.length === 0) return null;

  return employee.countryDetails
    ? employee.countryDetails.reduce((acc, country, i) => (i !== 0 ? `${acc}, ${country.Land}` : `${country.Land}`), '')
    : '';
};

/**
 * Gets the employee's station if it exists
 *
 * @param {object} employee
 */
export const getStation = (employee) => employee && employee.stationDetails && employee.stationDetails.Omschrijving;

export const getStatusColor = (status) => {
  switch (status) {
    case STATUS_CONFLICT:
    case STATUS_REVIEW: {
      return 'primary';
    }
    case STATUS_APPROVED: {
      return 'secondary';
    }
    case STATUS_DRAFT:
    case STATUS_INITIAL:
    default: {
      return 'lightgray';
    }
  }
};

/**
 * Create a clean version based on the previous version
 * by destructuring every property we don't need from an employee
 */
export const newVersion = (baseVersion) => {
  // eslint-disable-next-line no-unused-vars
  const { _id, _assignee, _approvedAt, _approvedBy, _createdAt,
  // eslint-disable-next-line no-unused-vars
    _createdBy, _rejectedBy, _rejectReason, _status, _startDate,
  // eslint-disable-next-line no-unused-vars
    _updatedAt, _updatedBy, comments,
    ...cleanVersion
  } = baseVersion;

  return cleanVersion;
};

export const employeeListColumns = [
  {
    value: '_createdBy',
    type: TYPE_TEXT,
    field: FIELD_USER,
    getCellValue: (row) => getFullUserName(row._createdByDetails),
    label: 'label.created_by',
  },
  {
    value: '_createdAt',
    type: TYPE_DATE_TIME_DAY,
    label: 'label.created_at'
  },
  {
    value: 'regulationSpecifics.salaryGross',
    type: TYPE_DECIMAL,
    label: 'filter.salary_gross',
  },
  {
    value: 'regulationSpecifics.salaryNet',
    type: TYPE_DECIMAL,
    label: 'filter.salary_net',
  },
  {
    value: 'regulationSpecifics.preMissionSalaryGross',
    type: TYPE_DECIMAL,
    label: 'filter.pre_mission_salary_gross',
  },
  {
    value: 'regulationSpecifics.preMissionSalaryNet',
    type: TYPE_DECIMAL,
    label: 'filter.pre_mission_salary_net',
  },
  {
    value: 'regulationSpecifics.seniorityDate',
    type: TYPE_DATE,
    label: 'filter.seniority_date',
  },
  {
    value: 'regulationSpecifics.preMissionSeniorityDate',
    type: TYPE_DATE,
    label: 'filter.pre_mission_seniority_date',
  },
  {
    value: 'manager',
    type: TYPE_TEXT,
    field: FIELD_USER,
    getCellValue: (row) => getFullUserName(row.managerDetails),
    label: 'filter.manager',
  },
  {
    value: 'paymentCode',
    type: TYPE_TEXT,
    label: 'filter.payment_code',
  },
  {
    value: '_updatedAt',
    type: TYPE_DATE_TIME_DAY,
    label: 'filter.updated_at',
  },
  {
    value: '_updatedBy',
    type: TYPE_TEXT,
    field: FIELD_USER,
    getCellValue: (row) => getFullUserName(row._updatedByDetails),
    label: 'filter.updated_by',
  },
  {
    value: 'bziamNumber',
    type: TYPE_TEXT,
    label: 'filter.bziam_number',
  },
  {
    value: 'formationPositionNumber',
    type: TYPE_TEXT,
    label: 'filter.formation_position_number',
  },
  {
    value: 'userGroupTP',
    type: TYPE_TEXT,
    label: 'filter.user_group_tp'
  },
  {
    value: 'regulationSpecifics.contractHours',
    type: TYPE_NUMBER,
    label: 'filter.contract_hours',
  },
  {
    value: 'regulationSpecifics.preMissionContractHours',
    type: TYPE_DECIMAL,
    label: 'filter.pre_mission_contract_hours',
  },
  {
    value: 'regulationSpecifics.forcedMoveDate',
    type: TYPE_DATE,
    label: 'filter.forced_move_date',
  },
  {
    value: 'regulationSpecifics.car',
    type: TYPE_BOOLEAN,
    label: 'filter.car',
  },
  {
    value: 'regulationSpecifics.transportCar',
    type: TYPE_BOOLEAN,
    label: 'filter.transportCar',
  },
  {
    value: 'regulationSpecifics.residence',
    type: TYPE_BOOLEAN,
    label: 'filter.residence',
  },
  {
    value: 'regulationSpecifics.residenceFurnished',
    type: TYPE_BOOLEAN,
    label: 'filter.residence_furnished',
  },
  {
    value: 'documentationUrl',
    type: TYPE_TEXT,
    label: 'filter.documentation_url',
  },
  {
    value: 'email',
    type: TYPE_TEXT,
    label: 'filter.email',
  },
  {
    value: 'regulationSpecifics.functionLevel',
    type: TYPE_NUMBER,
    label: 'filter.function_level',
    reference: 'c6bf2dc7-4942-4498-ab19-488a7d37073d',
    labelColumn: 'Beschrijving',
    valueColumn: 'Schaal'
  },
  {
    value: 'regulationSpecifics.functionLevelTemporary',
    type: TYPE_TEXT,
    label: 'filter.function_level_temporary',
    reference: 'c6bf2dc7-4942-4498-ab19-488a7d37073d',
    labelColumn: 'Beschrijving',
    valueColumn: 'Schaal',
  },
  {
    value: 'regulationSpecifics.functionLevelPartner',
    type: TYPE_NUMBER,
    label: 'filter.function_level_partner',
    reference: 'c6bf2dc7-4942-4498-ab19-488a7d37073d',
    labelColumn: 'Beschrijving',
    valueColumn: 'Schaal',
  },
  {
    value: 'regulationSpecifics.jobTitle',
    type: TYPE_TEXT,
    label: 'filter.job_title',
    reference: '5f9a30ba-9659-4c60-ae2d-9d90ba109386',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'dateOfBirth',
    type: TYPE_DATE,
    label: 'filter.date_of_birth',
  },
  {
    value: 'partner.dateOfBirth',
    type: TYPE_DATE,
    label: 'filter.date_of_birth_partner',
  },
  {
    value: 'fullName',
    type: TYPE_TEXT,
    label: 'filter.full_name',
    getCellValue: (row) => getSortableFullName(row)
  },
  {
    value: 'birthName',
    type: TYPE_TEXT,
    label: 'filter.birth_name',
  },
  {
    value: 'placeOfBirth',
    type: TYPE_TEXT,
    label: 'filter.place_of_birth',
  },
  {
    value: 'lastName',
    type: TYPE_TEXT,
    label: 'filter.last_name',
  },
  {
    value: 'generalName',
    type: TYPE_TEXT,
    label: 'filter.general_name',
  },
  {
    value: 'partner.birthName',
    type: TYPE_TEXT,
    label: 'filter.birth_name_partner',
  },
  {
    value: 'partner.generalName',
    type: TYPE_TEXT,
    label: 'filter.general_name_partner',
  },
  {
    value: 'regulationSpecifics.rentalAgreementEndDate',
    type: TYPE_DATE,
    label: 'filter.rental_agreement_end_date',
  },
  {
    value: 'regulationSpecifics.placementEndDate',
    type: TYPE_DATE,
    label: 'filter.placement_end_date',
  },
  {
    value: 'gender',
    type: TYPE_TEXT,
    label: 'filter.gender',
    reference: '4d74fe93-c48e-4cff-8bc1-2afc550c51e6',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'partner.gender',
    type: TYPE_TEXT,
    reference: '4d74fe93-c48e-4cff-8bc1-2afc550c51e6',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
    label: 'filter.gender_partner',
  },
  {
    value: 'isPriorityTandem',
    type: TYPE_BOOLEAN,
    label: 'filter.priority_tandem',
    getCellValue: (row) => (row.priorityTandem)
  },
  {
    value: 'regulationSpecifics.domesticStaffSA',
    type: TYPE_BOOLEAN,
    label: 'filter.domestic_staff_SA',
  },
  {
    value: 'regulationSpecifics.monthlyRent',
    type: TYPE_DECIMAL,
    label: 'filter.monthly_rent',
  },
  {
    value: 'regulationSpecifics.dailyRent',
    type: TYPE_DECIMAL,
    label: 'filter.daily_rent',
  },
  {
    value: 'regulationSpecifics.rentalResidence',
    type: TYPE_BOOLEAN,
    label: 'filter.rental_residence',
  },
  {
    value: '_startDate',
    type: TYPE_DATE,
    label: 'filter.start_date',
  },
  {
    value: 'regulationSpecifics.localSalary',
    type: TYPE_DECIMAL,
    label: 'filter.local_salary',
  },
  {
    value: 'lastNamePreference',
    type: TYPE_TEXT,
    label: 'filter.last_name_preference',
    reference: '6c230de0-4da7-412b-8cdd-d2becc952c62',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'regulationSpecifics.personType',
    type: TYPE_TEXT,
    label: 'label.person_type',
    reference: '90d50986-5994-4b9d-a9c1-8e75a8542be2',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'comments',
    type: TYPE_TEXT,
    label: 'filter.comments',
  },
  {
    value: 'updateReason',
    type: TYPE_TEXT,
    label: 'filter.update_reason',
    reference: '65c85dc3-442b-4a28-acf1-b212d126116d',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'regulationSpecifics.residenceEmbassy',
    type: TYPE_BOOLEAN,
    label: 'filter.residence_embassy',
  },
  {
    value: 'identityCode',
    type: TYPE_TEXT,
    label: 'filter.identity_code',
  },
  {
    value: 'regulationSpecifics.salaryScale',
    type: TYPE_NUMBER,
    label: 'filter.salary_scale',
    reference: 'c6bf2dc7-4942-4498-ab19-488a7d37073d',
    labelColumn: 'Beschrijving',
    valueColumn: 'Schaal',
  },
  {
    value: 'regulationSpecifics.preMissionSalaryScale',
    type: TYPE_NUMBER,
    label: 'filter.pre_mission_salary_scale',
    reference: 'c6bf2dc7-4942-4498-ab19-488a7d37073d',
    labelColumn: 'Beschrijving',
    valueColumn: 'Schaal',
  },
  {
    value: 'regulationSpecifics.sector',
    type: TYPE_TEXT,
    label: 'filter.sector',
    reference: 'f30135d2-fa0c-47ce-8151-3e65cd242e77',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'regulationSpecifics.preMissionSector',
    type: TYPE_TEXT,
    label: 'filter.pre_mission_sector',
    reference: 'f30135d2-fa0c-47ce-8151-3e65cd242e77',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'station',
    type: TYPE_TEXT,
    label: 'filter.station',
    reference: 'd37b14c7-f9e7-4103-a8ca-0ee844a50f37',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'partner.station',
    type: TYPE_TEXT,
    label: 'filter.station_partner',
    reference: 'd37b14c7-f9e7-4103-a8ca-0ee844a50f37',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'regulationSpecifics.stationTemporary',
    type: TYPE_TEXT,
    label: 'filter.station_temporary',
    reference: 'd37b14c7-f9e7-4103-a8ca-0ee844a50f37',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'status',
    type: TYPE_TEXT,
    label: 'filter.status',
    reference: '890449cd-ca3b-4888-9989-8fbb34188eb4',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'tandemId',
    type: TYPE_TEXT,
    field: FIELD_EMPLOYEE,
    getCellValue: (row) => getFullName(row.tandemIdDetails),
    label: 'filter.tandem_id',
  },
  {
    value: 'countryCode',
    type: TYPE_TEXT,
    label: 'filter.phone_number_country_code',
    reference: 'd5121d6d-56d2-4b20-9177-89324a331956',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'areaCode',
    type: TYPE_TEXT,
    label: 'filter.phone_number_area_code',
  },
  {
    value: 'phoneNumber',
    type: TYPE_TEXT,
    label: 'filter.phone_number',
  },
  {
    value: 'postNominalTitle',
    type: TYPE_TEXT,
    label: 'filter.post_nominal_title',
  },
  {
    value: 'title',
    type: TYPE_TEXT,
    label: 'filter.title',
  },
  {
    value: '_assignee',
    type: TYPE_TEXT,
    label: 'filter.assignee',
    getCellValue: (row) => getFullUserName(row._assigneeDetails),
    field: FIELD_USER
  },
  {
    value: 'middleName',
    type: TYPE_TEXT,
    label: 'filter.middle_name',
  },
  {
    value: 'partner.middleName',
    type: TYPE_TEXT,
    label: 'filter.middle_name_partner',
  },
  {
    value: 'regulation',
    type: TYPE_TEXT,
    label: 'filter.regulation',
    reference: '94507e65-1554-4c47-898d-5b0eef86e057',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code',
  },
  {
    value: 'regulationSpecifics.rentCurrency',
    type: TYPE_TEXT,
    label: 'filter.rent_currency',
    reference: 'b870ae1b-4815-46ee-9059-987b03093c49',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code'
  },
  {
    value: 'regulationSpecifics.dailyRentCurrency',
    type: TYPE_TEXT,
    label: 'filter.daily_rent_currency',
    reference: 'b870ae1b-4815-46ee-9059-987b03093c49',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code'
  },
  {
    value: 'regulationSpecifics.currencyLocalSalary',
    type: TYPE_TEXT,
    label: 'filter.currency_local_salary',
    reference: 'b870ae1b-4815-46ee-9059-987b03093c49',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code'
  },
  {
    value: '_status',
    field: FIELD_ENUM,
    type: TYPE_TEXT,
    label: 'filter.versie_status',
    enum: [
      { value: STATUS_DRAFT, label: 'status.draft' },
      { value: STATUS_REJECTED, label: 'status.rejected' },
      { value: STATUS_APPROVED, label: 'status.approved' },
      { value: STATUS_REVIEW, label: 'status.review' }
    ]
  },
  {
    value: 'firstNames',
    type: TYPE_TEXT,
    label: 'filter.first_names',
  },
  {
    value: 'initials',
    type: TYPE_TEXT,
    label: 'filter.initials',
  },
  {
    value: 'partner.initials',
    type: TYPE_TEXT,
    label: 'filter.initials_partner',
  },
  {
    value: 'partner.firstNames',
    type: TYPE_TEXT,
    label: 'filter.first_names_partner',
  },
  {
    value: 'regulationSpecifics.country',
    type: TYPE_TEXT,
    label: 'filter.country',
    getCellValue: (row) => getCountryDetailsLabels(row),
    reference: '10f342b5-afc8-4604-bb32-9c19bef61272',
    labelColumn: 'Land',
    valueColumn: 'Code'
  },
  {
    value: 'regulationSpecifics.employer',
    type: TYPE_TEXT,
    label: 'filter.employer',
    reference: 'd28e87fc-91b7-4bef-a422-1c98a7e3b49d',
    labelColumn: 'Omschrijving',
    valueColumn: 'Code'
  }
]
// add getCellValue for nested properties
  .map((attr) => {
    if (attr.value.includes('.') && !attr.getCellValue) {
      const props = attr.value.split('.');
      attr.getCellValue = (e) => props.reduce((val, attr) => (val && val[attr] ? val[attr] : null), e);
    }
    return attr;
  });
